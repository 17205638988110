export default {
  props: {
  },
  computed: {
    gridColumns() {
      let cols = null;
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'deviationName',
            field: 'deviationName',
            // 이탈
            label: 'LBL0001005',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: 'LBL0001026',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            fix: true,
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultCd',
            field: 'ramInspectResultCd',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:80px',
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선번호/개선진행상태
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: 'LBLJOB',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: 'LBL0001103',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: 'LBL0001008',
            // 개선전
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: 'LBLJOB',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: 'LBL0001008',
            // 개선전
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: 'LBLJOB',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 유해위험요인
            label: 'LBL0001050',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: 'LBL0001008',
            // 개선전
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        if (this.charmTypeName === 'estimation') {
          cols = [
            {
              fix: true,
              name: 'processName',
              field: 'processName',
              // 공정
              label: 'LBLPROCESS',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              fix: true,
              name: 'materialName',
              field: 'materialName',
              // 화학자재
              label: 'LBL0000356',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'chemName',
              field: 'chemName',
              // 구성물질
              label: 'LBL0001123',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'casNo',
              field: 'casNo',
              // CAS No.
              label: 'LBL0001124',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'riskEstimation',
              field: 'riskEstimation',
              // 위험도 추정
              label: 'LBL0001125',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              color: '#1976D2'
            },
            {
              name: 'exposureLevelFinal',
              field: 'exposureLevelFinal',
              // 최종노출수준
              label: 'LBL0001126',
              align: 'center',
              style: 'width:40px',
              sortable: false,
              color: '#1976D2'
            },
            {
              name: 'toxic',
              field: 'toxic',
              // 유해성
              label: 'LBL0001127',
              align: 'center',
              style: 'width:60px',
              sortable: false,
              color: '#1976D2'
            },
            {
              name: 'f',
              field: 'f',
              // 노출수준 측정
              label: 'LBL0001128',
              child: [
                {
                  name: 'suspectUserFlag',
                  field: 'suspectUserFlag',
                  // 유소견자 발생여부
                  label: 'LBL0001129',
                  align: 'center',
                  style: 'width:70px',
                  sortable: false,
                  type: 'check',
                  true: 'Y',
                  false: 'N'
                },
                {
                  name: 'workMeasure',
                  field: 'workMeasure',
                  // 작업환경측정
                  label: 'LBL0001130',
                  child: [
                    {
                      name: 'measValue',
                      field: 'measValue',
                      // 측정치(A)
                      label: 'LBL0001131',
                      align: 'right',
                      style: 'width:80px',
                      sortable: false,
                    },
                    {
                      name: 'exposureStandard',
                      field: 'exposureStandard',
                      // 노출기준(B)
                      label: 'LBL0001132',
                      align: 'right',
                      style: 'width:80px',
                      sortable: false,
                    },
                    {
                      name: 'exposureMeasValue',
                      field: 'exposureMeasValue',
                      // A/B(%)
                      label: 'LBL0001133',
                      align: 'right',
                      style: 'width:65px',
                      sortable: false,
                    },
                  ]
                },
                {
                  name: 'daily',
                  field: 'daily',
                  // 하루 취급량, 비산성/휘발성, 밀폐·환기상태
                  label: 'LBL0001134',
                  child: [
                    {
                      name: 'limitRepval',
                      field: 'limitRepval',
                      // 함유량
                      label: 'LBL0001135',
                      align: 'center',
                      style: 'width:60px',
                      sortable: false,
                    },
                    {
                      name: 'dailyVolume',
                      field: 'dailyVolume',
                      // 일취급량
                      label: 'LBL0001136',
                      align: 'center',
                      style: 'width:70px',
                      sortable: false,
                    },
                    {
                      name: 'volumeUnitName',
                      field: 'volumeUnitName',
                      label: 'LBLUNIT',
                      align: 'center',
                      style: 'width:50px',
                      sortable: false,
                    },
                    {
                      name: 'dailyLevel',
                      field: 'dailyLevel',
                      // 일취급 수준
                      label: 'LBL0001137',
                      align: 'center',
                      style: 'width:50px',
                      sortable: false,
                    },
                    {
                      name: 'maleficenceType',
                      field: 'maleficenceType',
                      // 발생형태
                      label: 'LBL0001138',
                      align: 'center',
                      style: 'width:80px',
                      sortable: false,
                      type: 'select',
                      codeGroupCd: 'MALEFICENCE_TYPE'
                    },
                    {
                      name: 'maleficenceGrade',
                      field: 'maleficenceGrade',
                      // 비산성
                      label: 'LBL0001139',
                      align: 'center',
                      style: 'width:80px',
                      sortable: false,
                      disableTarget: 'maleficenceType',
                      disableCon: 'MT00000001',
                      type: 'select',
                      codeGroupCd: 'MALEFICENCE_GRADE'
                    },
                    {
                      name: 'boilingPoint',
                      field: 'boilingPoint',
                      // 끓는점
                      label: 'LBL0001140',
                      align: 'right',
                      style: 'width:60px',
                      sortable: false,
                      disableTarget: 'maleficenceType',
                      disableCon: 'MT00000002',
                    },
                    {
                      name: 'volatilityGradeName',
                      field: 'volatilityGradeName',
                      // 휘발성
                      label: 'LBL0001141',
                      align: 'center',
                      style: 'width:60px',
                      sortable: false,
                    },
                    {
                      name: 'exposureLevelCombination',
                      field: 'exposureLevelCombination',
                      // 노출수준
                      label: 'LBL0001142',
                      align: 'center',
                      style: 'width:40px',
                      sortable: false,
                    },
                    {
                      name: 'closedVentilation',
                      field: 'closedVentilation',
                      // 밀폐·환기상태
                      label: 'LBL0001143',
                      align: 'center',
                      style: 'width:100px',
                      sortable: false,
                      type: 'select',
                      none: 'none',
                      codeGroupCd: 'CLOSED_VENTILATION'
                    },
                  ],
                },
              ]
            },
            {
              name: 's',
              field: 's',
              // 유해성 측정
              label: 'LBL0001144',
              child: [
                {
                  name: 'cmrFlag',
                  field: 'cmrFlag',
                  // CMR여부
                  label: 'LBL0001152',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false,
                  type: 'check',
                  true: 'Y',
                  false: 'N'
                },
                {
                  name: 'measureCheck',
                  field: 'measureCheck',
                  // 노출기준확인
                  label: 'LBL0001145',
                  child: [
                    {
                      name: 'maleficenceForm',
                      field: 'maleficenceForm',
                      // 발생형태
                      label: 'LBL0001138',
                      align: 'center',
                      style: 'width:110px',
                      sortable: false,
                      disableTarget: 'cmrFlag',
                      disableCon: 'N',
                      type: 'select',
                      codeGroupCd: 'MALEFICENCE_FORM'
                    },
                    {
                      name: 'maleficenceValue',
                      field: 'maleficenceValue',
                      // 측정값
                      label: 'LBL0001146',
                      align: 'right',
                      style: 'width:80px',
                      sortable: false,
                      disableTarget: 'cmrFlag',
                      disableCon: 'N',
                    },
                  ]
                },
                {
                  name: 'hrCodeGrade',
                  field: 'hrCodeGrade',
                  // 위험문구/유해·위험문구
                  label: 'LBL0001147',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false,
                  disableTarget: 'cmrFlag',
                  disableCon: 'N',
                  type: 'select',
                  codeGroupCd: 'HR_CODE_GRADE'
                },
              ]
            },
          ]
        } else {
          cols = [
            {
              fix: true,
              name: 'processName',
              field: 'processName',
              label: 'LBLPROCESS',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },  
            {
              fix: true,
              name: 'materialName',
              field: 'materialName',
              // 화학자재
              label: 'LBL0000356',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'existingRiskManagementActivities',
              field: 'existingRiskManagementActivities',
              // 현재안전보건조치
              label: 'LBL0001148',
              align: 'left',
              style: 'width:300px',
              sortable: false,
            },
            {
              name: 'before',
              field: 'before',
              // 개선전
              label: 'LBL0001008',
              align: 'center',
              child: [
                {
                  name: 'beforeExposureLevelFinal',
                  field: 'beforeExposureLevelFinal',
                  // 노출수준
                  label: 'LBL0001142',
                  align: 'center',
                  style: 'width:45px',
                  sortable: false
                },
                {
                  name: 'beforeToxic',
                  field: 'beforeToxic',
                  // 유해성
                  label: 'LBL0001127',
                  align: 'center',
                  style: 'width:50px',
                  sortable: false
                },
                {
                  name: 'beforeRiskEstimation',
                  field: 'beforeRiskEstimation',
                  // 위험도
                  label: 'LBL0001011',
                  align: 'center',
                  style: 'width:70px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'addingRiskManagementActivies',
              field: 'addingRiskManagementActivies',
              // 감소대책
              label: 'LBL0001149',
              align: 'left',
              style: 'width:300px',
              sortable: false,
            },
            {
              name: 'after',
              field: 'after',
              // 개선후
              label: 'LBL0001012',
              align: 'center',
              child: [
                {
                  name: 'afterExposureLevelFinal',
                  field: 'afterExposureLevelFinal',
                  // 노출수준
                  label: 'LBL0001142',
                  align: 'center',
                  style: 'width:45px',
                  sortable: false
                },
                {
                  name: 'afterToxic',
                  field: 'afterToxic',
                  // 유해성
                  label: 'LBL0001127',
                  align: 'center',
                  style: 'width:50px',
                  sortable: false
                },
                {
                  name: 'afterRiskEstimation',
                  field: 'afterRiskEstimation',
                  // 위험도
                  label: 'LBL0001011',
                  align: 'center',
                  style: 'width:70px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'assessDate',
              field: 'assessDate',
              // 평가일
              label: 'LBL0001013',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'assessUserName',
              field: 'assessUserName',
              // 평가자
              label: 'LBL0001014',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'customCol',
              field: 'customCol',
              // 개선번호/개선진행상태
              label: 'LBLIMPRNO_PROGRESS',
              align: 'center',
              style: 'width:300px',
              type: 'custom',
              sortable: false
            },
          ]
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            // 유해위험요인<br/>(위험한 상황과 사건)
            label: 'LBL0001150',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ramRiskLevelName',
            field: 'ramRiskLevelName',
            // 판단결과
            label: 'LBL0001151',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      }
      return cols;
    },
    gridMerge() {
      let merge = [];
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'processDiagram' }, // 도면
          { index: 2, colName: 'processDiagramNode' }, // 노드
          { index: 3, colName: 'processDiagramNodeGuide' }, // 이탈
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'diagramGubun' }, // 도면
          { index: 2, colName: 'nodeGubun' }, // 노드
          { index: 3, colName: 'riskTypeGubun' }, // 위험형태
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        // 일단 보류, check-list 구성 후 다시 진행
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 0, colName: 'checkClassGroup' }, // 분류
          { index: 0, colName: 'checkItemName' }, // 유해위험요인
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'jobStepGubun' }, // 작업단계
          { index: 3, colName: 'riskHazardGubun' }, // 유해위험요인
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'riskHazardGubun' }, // 유해위험요인
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'riskHazardGubun' }, // 유해위험요인 (구분과 같이)
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        if (this.charmTypeName === 'estimation') {
          merge = [
            { index: 0, colName: 'processCd' }, // 공정
            { index: 1, colName: 'materialName' }, // 화학자재
          ]
        } else {
          merge = [
            { index: 0, colName: 'processCd' }, // 공정
          ]
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
        ]
      }
      return merge;
    },
    imprProps() {
      let props = {};
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        props = {
          tableKey: 'ramHazopAssessScenarioId', ibmTaskTypeCd: 'ITT0000001', ibmTaskUnderTypeCd: 'ITTU000001',
          requestContentsCols: ['deviationName', 'nodeVariableDesignIntent']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        props = {
          tableKey: 'ramKpsrAssessScenarioId', ibmTaskTypeCd: 'ITT0000005', ibmTaskUnderTypeCd: 'ITTU000005',
          requestContentsCols: ['riskTypName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        // 일단 보류, check-list 구성 후 다시 진행
        props = {
          tableKey: 'ramChecklistAssessScenarioId', ibmTaskTypeCd: 'ITT0000010', ibmTaskUnderTypeCd: 'ITTU000010',
          requestContentsCols: ['ramCheckItemClassName', 'checkItemName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        props = {
          tableKey: 'ramJsaAssessScenarioId', ibmTaskTypeCd: 'ITT0000015', ibmTaskUnderTypeCd: 'ITTU000020',
          requestContentsCols: ['jobStepName', 'riskHazardNameClass', 'riskHazardName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        props = {
          tableKey: 'ramKrasAssessScenarioId', ibmTaskTypeCd: 'ITT0000020', ibmTaskUnderTypeCd: 'ITTU000025',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        props = {
          tableKey: 'ram4mAssessScenarioId', ibmTaskTypeCd: 'ITT0000023', ibmTaskUnderTypeCd: 'ITTU000030',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskHazardName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        // 사용 X
        props = {
          tableKey: 'ramCharmResultAssessScenarioId', ibmTaskTypeCd: 'ITT0000024', ibmTaskUnderTypeCd: 'ITTU000035',
          requestContentsCols: ['materialName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        props = {
          tableKey: 'ramThirdAssessScenarioId', ibmTaskTypeCd: 'ITT0000013', ibmTaskUnderTypeCd: 'ITTU000015',
          requestContentsCols: ['riskHazardQuestionName']
        }
      }
      return props;
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
